import React from "react";
import styled from "styled-components";
import {ITitle, Size} from "./types";
import {styles} from "./constants";

export const Title: React.FC<ITitle> = ({className, children, size = "lg"}) => {
  return (
    <TitleWrap className={className} size={size}>
      {children}
    </TitleWrap>
  );
};

const TitleWrap = styled.div<ITitle>`
  color: var(--text-01);
  ${({theme, size}) => theme.fontTokens[styles.size[size as Size]]}
`