import {renderUser} from "entities/user";

export const props = [
  {
    title: "Дизайнер",
    content: renderUser()
  },
  {
    title: "Разработчик",
    content: renderUser()
  },
  {
    title: "Взято в работу",
    content: "07.09.2024 в 16:48"
  }
]