import React, {useEffect} from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { IBaseComponent } from "shared/types";
import { Title, Stack, Priority, DateTime, PropertyList, StorybookIcon, FigmaIcon } from "shared/ui";
import { useTelegram } from "app/providers";
import { props } from "./model";

export const TaskPage: React.FC<IBaseComponent> = ({className}) => {
  const { tg } = useTelegram();
  const navigate = useNavigate();

  useEffect(() => {
    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      navigate('/tasks')
    });

    tg.MainButton.text = "Взять в работу";
    tg.MainButton.show();

    return () => {
      tg.BackButton.hide();
      tg.MainButton.hide();
    }
  }, [])

  return (
    <TaskPageWrap className={className}>
      <Stack gap="06">
        <Stack gap="04">
          <div className="header">
            <Priority type="to"/>
            <DateTime date="14 сент в 18:00"/>
          </div>
          <Title>Pills</Title>
          <div className="description">
            Добавлен новый компонент. Есть вариации с иконкой и только текст.
            Крайне необходим для команды Новый ККР.
          </div>
        </Stack>
        <div className="link-list">
          <div className="link">
            Дизайн-система «ЕФР»
            <FigmaIcon/>
          </div>
          <div className="link">
            Компонент отсутствует
            <StorybookIcon/>
          </div>
        </div>
        <PropertyList items={props}/>
      </Stack>
    </TaskPageWrap>
  );
};

const TaskPageWrap = styled.div`
  padding-bottom: var(--spacing--12);
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: var(--spacing--03);
  }

  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--tg-theme-subtitle-text-color);
    font-size: 14px;
  }
  
  .link-list {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing--05);
    
    .link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: var(--spacing--03);
      padding: var(--spacing--03);
      border-radius: var(--radius-02, 6px);
      border: 1px solid var(--Wallet-separator_color, #C8C7CB);
      font-size: 13px;
      font-weight: 510;
      max-width: 155px;
    }
  }
`