import React from 'react';
import styled from "styled-components";
import { IPropertyList } from './types';

export const PropertyList: React.FC<IPropertyList> = ({ items }) => {
  return (
    <PropertyListWrap>
      {items.map((item, index) => (
        <div className="item" key={index}>
          <h2>{item.title}</h2>
          <div>{item.content}</div>
        </div>
      ))}
    </PropertyListWrap>
  );
};

const PropertyListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing--06);
  
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--tg-theme-text-color);

    h2 {
      color: var(--tg-theme-subtitle-text-color);
      font-size: 14px;
      font-style: normal;
    }
  }
`