import React from "react";
import styled from "styled-components";
import { IBaseComponent } from "shared/types";
import avatar from "shared/assets/avatar.png";

export const User: React.FC<IBaseComponent> = ({className}) => {
  return (
    <UserWrap className={className}>
      <img src={avatar} alt="Абдеев Роман"/>
      <span>Абдеев Роман</span>
    </UserWrap>
  );
};

const UserWrap = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing--03);
  font-size: 14px;
  color: var(--tg-theme-text-color);
`