import React from "react";
import styled from "styled-components";
import { types } from "./constants";
import { IPriority } from "./types";

export const Priority: React.FC<IPriority> = ({className, type}) => {
  return (
    <PriorityWrap className={className} type={type}>
      <img src={types[type].img} alt={types[type].text}/>
      <span>{types[type].text}</span>
    </PriorityWrap>
  );
};

const PriorityWrap = styled.div<IPriority>`
  display: flex;
  padding: var(--spacing--02) var(--spacing--03);
  justify-content: center;
  align-items: center;
  gap: var(--spacing--02);
  border-radius: var(--radius-04);
  background: var(--button-destructive-color);

  color: ${({type}) => type === "ur" ? "var(--accent-red)" : "var(--accent-black)"};
  font-size: 13px;
  
  img {
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
  }
`