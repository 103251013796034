import React from "react";
import { IRouteItem } from "shared/types";
import { HomePage } from "./home";
import { TasksPage } from "./tasks";
import { TaskPage } from "./task";

export const routes: IRouteItem[] = [
  {
    name: "Главная",
    key: "home",
    path: "/",
    component: <HomePage/>,
  },
  {
    name: "Задачи",
    key: "tasks",
    path: "/tasks",
    component: <TasksPage/>,
  },
  {
    name: "Задача",
    key: "task",
    path: "/task/:id",
    component: <TaskPage/>,
  },
]