import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {DateTime, Priority} from "shared/ui";
import {createTaskLink} from "../lib";
import {ITaskCard} from "./types";

export const TaskCard: React.FC<ITaskCard> = ({id, className, priority = "to"}) => {
  return (
    <TaskCardWrap to={createTaskLink(id)} className={className}>
      <div className="header">
        <Priority type={priority}/>
        <DateTime date="14 сент в 18:00"/>
      </div>
      <div className="content">
        <div className="title">Pills</div>
        <div className="description">Добавлен новый компонент. Есть вариации с иконкой и только текст. Крайне необходим для команды Новый ККР.</div>
      </div>
    </TaskCardWrap>
  );
};

const TaskCardWrap = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: var(--spacing--05);
  gap: var(--spacing--05);
  border-radius: var(--spacing--04);
  border: 1px solid var(--wallet-separator-color);
  text-decoration: none !important;
  align-items: flex-start;
  align-self: stretch;
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: var(--spacing--03);
  }
  
  .content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing--02);
    ${({theme}) => theme.fontTokens.label01}

    .title {
      font-size: 20px;
      font-weight: bold;
      color: var(--accent-black);
    }
    
    .description {
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--tg-theme-subtitle-text-color);
      font-size: 14px;
    }
  }
`