import React from "react";
import styled from "styled-components";
import { Menu } from "widgets/menu";
import { IBaseComponent } from "shared/types";
import { Title, Stack } from "shared/ui";
import { TaskList } from "entities/task";

export const TasksPage: React.FC<IBaseComponent> = ({className}) => {
  return (
    <TasksPageWrap className={className}>
      <Stack gap="04">
        <Title>Задачи</Title>
        <TaskList/>
        <Menu/>
      </Stack>
    </TasksPageWrap>
  );
};

const TasksPageWrap = styled.div`
  padding-bottom: var(--spacing--12);
`