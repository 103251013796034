import okImg from "../../assets/ok.png";
import fireImg from "../../assets/fire.png";

interface ITypes {
  [key: string]: {
    text: string,
    img: string
  }
}

export const types: ITypes = {
  ur: {
    text: "Срочно",
    img: fireImg
  },
  to: {
    text: "Терпимо",
    img: okImg
  }
}