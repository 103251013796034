import React, { useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Container } from "shared/ui";
import { routes } from "pages";
import { RouterProvider, useTelegram } from "./providers";
import { GlobalStyle } from './styles'
import { theme } from './theme';

export const App = () => {
  const { tg } = useTelegram();

  useEffect(() => {
    tg.ready();
    tg.expand();
  }, [])

  return (
   <>
     <GlobalStyle />
     <ThemeProvider theme={theme}>
       <Container>
         <BrowserRouter>
           <RouterProvider routes={routes}/>
         </BrowserRouter>
       </Container>
     </ThemeProvider>
   </>
  );
}
