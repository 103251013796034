import React from "react";
import { Routes, Route } from "react-router-dom";
import {IRouterProvider} from "./types";
import {IRouteItem} from "shared/types";

export const RouterProvider: React.FC<IRouterProvider> = ({ routes }) => {
  const renderRoute = ({key, path, component}: IRouteItem) => {
    return (
      <Route key={key} index={path === '/'} path={path} element={component}/>
    )
  }

  return (
    <Routes>
      {routes.map(route => renderRoute(route))}
    </Routes>
  );
};