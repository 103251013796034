import React from "react";
import styled from "styled-components";
import {IBaseComponent} from "shared/types";
import {Stack} from "shared/ui";
import {TaskCard} from "./TaskCard";

export const TaskList: React.FC<IBaseComponent> = ({className}) => {
  return (
    <TaskListWrap gap="03" className={className}>
      <TaskCard id={1} priority="ur"/>
      <TaskCard id={2}/>
      <TaskCard id={3}/>
      <TaskCard id={4}/>
      <TaskCard id={5}/>
      <TaskCard id={5}/>
      <TaskCard id={5}/>
      <TaskCard id={5}/>
      <TaskCard id={5}/>
    </TaskListWrap>
  );
};

const TaskListWrap = styled(Stack)`

`